import React, { useState } from 'react';

export const themeContext = React.createContext();

const Provider = props => {
    const [darkMode, setDarkMode] = useState(localStorage !== undefined ? (localStorage.getItem('rpModeSetting') || false) : false);

    const toggleDarkMode = (mode) => {
        const setting = !mode;
        setDarkMode(setting);
        if (localStorage !== undefined) {
            localStorage.setItem('rpModeSetting', setting);
        }
    }

    return (
        <themeContext.Provider value={{
            darkMode,
            changeThemeMode: () => toggleDarkMode(darkMode)
        }}>
            {props.children}
        </themeContext.Provider>
    )
}

export default ({ element }) => (
    <Provider>
      {element}
    </Provider>
);